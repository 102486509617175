import React from "react";
import Banner from "../components/Banner";
import { Helmet } from "react-helmet";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import Layout from "../components/layout";

const ServiceDetail = () => {
  const banner = {
    title: `GatsbyJS Development`,
    content:
      "As a Gatsby.js Development Company, we provide expert services for developing, customizing, and maintaining web applications built with the GatsbyJS development expertise.",
  };

  const commonContent6 = {
    title: "GatsbyJS Development Services",
    para: "With our GatsbyJS development expertise, you can expect excellent functionality and scalability using modern web standards that include the latest features.",
    img: ["what-service-image-gatsbyjs.webp"],
    imageTitle: 'GatsbyJS Development',
    imageAlt: 'We are a leading GatsbyJS development company',
    content: [
      {
        title: "User Research",
        text: "Our GatsbyJS development provides user-friendly and efficient solutions by learning about their preferences.",
      },
      {
        title: "Custom Website Development",
        text: "With our GatsbyJS development, you can expect quick load times and pleasant user experiences.",
      },
      {
        title: "Usability Testing",
        text: "Our Gatsby development expertise evaluates website performance and makes ideas for improvement.",
      },
      {
        title: "UI UX Design",
        text: "Our Gatsby development company performs research to understand consumer wants and preferences better.",
      },
      {
        title: "GatsbyJS Expertise",
        text: "Our Gatsby development services produce bespoke GatsbyJS plugins to enhance website functionality.",
      },
      {
        title: "Wireframes and Prototypes",
        text: "As a leading GatsbyJS web development agency, we create wireframes and prototypes to bring design thoughts to life.",
      },
      {
        title: "Gatsby Front-End Development",
        text: "Get a reliable Gatsby front-end development service to create user interfaces and front-end components with the GatsbyJS framework.",
      },
      {
        title: "Gatsby PWA Development",
        text: "Our Gatsby web development agency entails creating web applications that leverage the GatsbyJS framework to provide customers with a native-like experience.",
      },
    ],
  };

  const commonContent = {
    title: "Revolutionize Industry with our Gatsby Development",
    para: "Our GatsbyJS development company creates dynamic websites and redesigns existing ones for various industries, from startups to large corporations.",
    img: ["who-needs-gatsbyjs.webp"],
    imageTitle: 'Gatsby Development',
    imageAlt: 'Revolutionizing Industry with our Gatsby Development',
    content: [
      {
        title: "E-commerce",
        text: "Companies seek to create dynamic and highly functional websites to give seamless website experiences to their customers.",
      },
      {
        title: "SMBs",
        text: "Companies are looking to boost their online presence using low-cost alternatives for designing websites based on consumer requirements.",
      },
      {
        title: "Startups",
        text: "Companies looking to grow their business by embracing cutting-edge tools and technology.",
      },
      {
        title: "Enterprises",
        text: "Companies that wish to simplify their complex platform while providing their clients with a high-performing, reliable platform for a better experience.",
      },
    ],
  };

  const commonContent2 = {
    title: `Choose Different with <span class="h1-span">Gatsby Web Development</span>`,
    para: "GatsbyJS Development Services enables companies to construct powerful interfaces using syntax and complete tooling to create highly functional, responsive, and intuitive applications.",
    img: ["what-are-benefits-gatsbyjs-services.webp"],
    imageTitle: 'Gatsby web development',
    imageAlt: 'choose different by choosing Gatsby web development',
    content: [
      {
        title: "Increased Efficiency",
        text: "GatsbyJS development provides reusable components and streamlines workflows, reducing development time and increasing productivity.",
      },
      {
        title: "Scalability",
        text: "GatsbyJS web development agency provides scalable solutions for your business, keeping your website updated with the latest trends while ensuring performance.",
      },
      {
        title: "Enhanced User Experience",
        text: "GatsbyJS development expertise helps create visually interactive, functional, responsive websites that improve user experience.",
      },
      {
        title: "Extensive Ecosystem",
        text: "Gatsby web development companies offer an innovative solution for your business through its extensive plugins, community-driven tools, and libraries.",
      },
    ],
  };
  const commonContent3 = {
    title: "Work with the Best GatsbyJS Web Development Agency",
    linkText: ``,
    para: `Octet Design Studio is a top-rated GatsbyJS Web Development Agency that creates innovative interfaces using our significant industry knowledge. We use our expertise in GatsbyJS Development Servies to develop customized and robust online solutions for your company.`,
    img: ["why-opt-for-gatsbyjs.webp"],
    imageTitle: 'Best Gatsby Company',
    imageAlt: 'Work with the best GatsbyJS web development agency',
    content: [
      {
        title: "Expertise And Experience",
        text: "We have appropriate expertise and experience in providing GatsbyJS Development Services and better than expected results.",
      },
      {
        title: "Customized Solutions",
        text: "We take a personalized approach to each project and provide tailored Gatsby solutions depending on your business objectives.",
      },
      {
        title: "Dedicated Team",
        text: "We provide a specialized team for your Gatsby web development, who will turn your ideas into reality.",
      },
      {
        title: "Timely Delivery",
        text: "We streamline the development process to ensure your projects run smoothly and on time.",
      },
    ],
  };
  const commonContent4 = {
    title: "Octet’s GatsbyJS Development Process",
    para: "We provide complete and personalized GatsbyJS development services based on your project requirements, ensuring seamless integration and business success.",
    img: ["how-we-conduct-gatsby-js-services.webp"],
    imageTitle: 'Gatsby Process',
    imageAlt: 'Octet’s Gatsby development process',
    content: [
      {
        title: "1. Define UX and Flows",
        text: "We initially identify the UX characteristics and user processes to determine the design structure.",
      },
      {
        title: "3. Create HTML Templates",
        text: "We transform the UI design into HTML templates, which are then translated into Gatsby pages.",
      },
      {
        title: "2. Create UI Designs",
        text: "We create designs while considering brand requirements, typographic design principles, color theory, and UX rules.",
      },
      {
        title: "4. Convert to GatsbyJS Pages.",
        text: "Finally, we transfer the HTML code to the GatsbyJS framework for easy comprehension and faster deployment.",
      },
    ],
  };
  const commonContent5 = {
    title:
      "Why Invest in the GatsbyJS Development Company?",
    para: "GatsbyJS is a progressive framework for developing dynamic and interactive interfaces. Investing in our GatsbyJS Web Development Agency enables your company to create cost-effective, high-quality websites.",
    content: [
      {
        title: "30% Less Development Time",
        text: "Gatsby’s simplicity and reactivity can help accelerate development cycles, lowering development time and other resource expenditures.",
      },
      {
        title: "20% Code Reusability",
        text: "The GatsbyJS development framework's component-based architecture promotes code reuse and maintainability, potentially increasing returns.",
      },
      {
        title: "10% Increased Productivity",
        text: "Gatsby development’s excellent documentation and component-based architecture increase developer productivity by up to 10%.",
      }, 
      {
        title: "10% Increased Productivity",
        text: "Gatsby's excellent documentation and component-based architecture increase developer productivity and efficiency by up to 10%.",
      },
    ],
    desc: "*These statistics are from public sources; we do not own them.",
  };
  const cta = {
    title: "Start your Business Journey with Gatsby Development",
  };
  const cta2 = {
    title: "Explore New Horizons <span class='h1-span'>with Us</span>",
  };
  const cta3 = {
    title: "Let’s Give Vision to Your Ideas",
  };
  const cta4 = {
    title: "Start Growing Digital With Octet",
  };
  const cta5 = {
    title: "Let’s Develop Something Extraordinary",
  };
  const tabs = [
    {
      link: '/angularjs-development/',
      text: 'Angular Development'
    },
    {
      link: '/branding/',
      text: 'Branding'
    },
    {
      link: '/corporate-training-workshop/',
      text: 'UI UX Workshops and Training'
    },
    {
      link: '/data-visualisation/',
      text: 'Dashboards and Data Visualisation'
    },
    {
      link: '/design-audit/',
      text: 'Design Audit '
    },
    {
      link: '/design-systems/',
      text: 'Design System Engineering'
    },
    {
      link: '/digital-transformation/',
      text: 'Digital Transformation'
    },
    {
      link: '/product-development/',
      text: 'Product Development'
    },
    {
      link: '/rapid-prototyping/',
      text: 'Rapid Prototyping'
    },
    {
      link: '/reactjs-development/',
      text: 'ReactJS Development'
    },
    {
      link: '/ui-development/',
      text: 'UI Development'
    },
    {
      link: '/ui-ux-designing/',
      text: 'UI UX Designing'
    },
    {
      link: '/usability-testing/',
      text: 'Usability Testing'
    },
    {
      link: '/user-acquisition-consulting/',
      text: 'User Acquisition Consultation'
    },
    {
      link: '/user-research/',
      text: 'User Research'
    },
    {
      link: '/ux-consulting/',
      text: 'UX Consultation'
    },
    {
      link: '/vuejs-development/',
      text: 'VueJS Development'
    },
    {
      link: '/wordpress-development/',
      text: 'WordPress Development'
    },
  ];
  const projectData = [
    {
      title: 'Nirvana',
      desc: 'A financial solutions platform',
      Industry: 'FinTech',
      WhatWeDid: [
          {
              title: 'Design Audit',
              link: '/design-audit/'
          },
          {
              title: 'UI UX Designing',
              link: '/ui-ux-designing/'
          },
          {
              title: 'React Development',
              link: '/reactjs-development/'
          }
      ],
      link: '/project/nirvana/',
      images: ["nirvana-project.webp", "nirvana-project-2.webp"],
      firstAlt: "Custom Web Development",
      firstTitle: "Orders List",
      secondAlt: "Web Development Services",
      secondTitle: "Order Status",
    },
    {
        title: "Ship Delight",
        desc: "An E-Commerce Logistic Website",
        Industry: "Logistics",
        firstAlt: "UI UX Dashbaord To Check The Real Time Shipment Status",
        firstTitle: "Real Time Shipment Status",
        secondAlt: "UI Dashboard To Check The Performance Status",
        secondTitle: "NDR Status",
        WhatWeDid: [
          {
            title: "Branding",
            link: "/branding/",
          },
          {
            title: "UI UX Designing",
            link: "/ui-ux-designing/",
          },
          {
            title: "Wordpress Development",
            link: "/wordpress-development/",
          },
        ],
        link: "/project/shipdelight/",
        images: ["ship-delight-web.webp", "ship-delight-web-2.webp"],
      },
  ];

  const Questions = [
    "Can you customize GatsbyJS Development Services?",
    "Is Gatsby Development suitable for rapid prototyping?",
    "Can you integrate GatsbyJS into existing web applications?",
    "How does your GatsbyJS Development Expertise improve user experience?",
    "What is the duration and cost of your GatsbyJS Development Services?",
  ];
  const clientsTitle = "Trusted By Clients Worldwide";
  const faqDes =
    `We understand the questions you may have concerning our GatsbyJS Development Services. In this area, we share additional information on our GatsbyJS Development Company. However, if you still have any questions, please <a title='contact us' target="_blank" rel="noreferrer" href="/contact-us/">contact us</a>.`;
  
    const faqData = [
      {
        para: [
          `Yes, we apply a flexible approach to offer customized solutions for your business to meet your specific demands.`,
          `Gatsby development is a flexible and versatile Framework that we use for customization and offers robust web solutions.`,
          `To offer a tailor-made GatsbyJS Development service and enhance your user experience, we understand your business objectives, target audience, and specific project requirements.`
        ],
      },
      {
        para: [
          `Yes, Gatsby is suitable for Rapid Prototyping because of its ease of use. It is an efficient model as its data binding and components-based architecture allow <a title="rapid prototyping" target="_blank" href="/rapid-prototyping/">rapid prototyping</a> building and iterations.`,
          `GatsbyJS also offers pre-built UI components and libraries that accelerate the prototyping process by gathering feedback on the ideas, refining designs, and delivering high-quality solutions.`
        ],
      },
      {
        para: [
          `Yes, we can seamlessly integrate our GatsbyJS Development Expertise into existing applications because of the platform's versatility and flexibility.`,
          `We consider Gatsby to be the best option for enhancing the functionality of your application.`,
          `It assists in improving your business's performance, enhances user experience, and offers easily scalable options.`
        ],
      },
      {
        para: [
          `Our GatsbyJS Development Expertise enables us to provide highly creative and responsive user interfaces based on your business requirements.`,
          `Through Gatsby, we develop dynamic websites and update them using the latest technologies to create an engaging interface.`,
          `With our flexible approach and GatsbyJS library, we offer optimized websites, improved usability, and an intuitive user experience.`
        ],
      },
      {
        para: [
          `The duration and cost of our GatsbyJS Development Services depend on various factors, such as the complexity and scope of your project, customizations needed, integration required, and more. Hence, we request that you <a title="contact us" href="/contact-us/" target="_blank">contact us</a> for an accurate timeline and detailed quotation.`
        ],
      },
    ];
  
    return (
      <>
        <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [{
                  "@type": "Question",
                  "name": "Can you customize GatsbyJS Development Services?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, we apply a flexible approach to offer customized solutions for your business to meet your specific demands.
              Gatsby development is a flexible and versatile Framework that we use for customization and offers robust web solutions.
              To offer a tailor-made GatsbyJS Development service and enhance your user experience, we understand your business objectives, target audience, and specific project requirements."
                  }
                },{
                  "@type": "Question",
                  "name": "Is Gatsby Development suitable for rapid prototyping?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, Gatsby is suitable for Rapid Prototyping because of its ease of use. It is an efficient model as its data binding and components-based architecture allow rapid prototyping building and iterations.
              GatsbyJS also offers pre-built UI components and libraries that accelerate the prototyping process by gathering feedback on the ideas, refining designs, and delivering high-quality solutions."
                  }
                },{
                  "@type": "Question",
                  "name": "Can you integrate GatsbyJS into existing web applications?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, we can seamlessly integrate our GatsbyJS Development Expertise into existing applications because of the platform's versatility and flexibility.
              We consider Gatsby to be the best option for enhancing the functionality of your application.
              It assists in improving your business's performance, enhances user experience, and offers easily scalable options."
                  }
                },{
                  "@type": "Question",
                  "name": "How does your GatsbyJS Development Expertise improve user experience?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Our GatsbyJS Development Expertise enables us to provide highly creative and responsive user interfaces based on your business requirements.
              Through Gatsby, we develop dynamic websites and update them using the latest technologies to create an engaging interface.
              With our flexible approach and GatsbyJS library, we offer optimized websites, improved usability, and an intuitive user experience."
                  }
                },{
                  "@type": "Question",
                  "name": "What is the duration and cost of your GatsbyJS Development Services?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The duration and cost of our GatsbyJS Development Services depend on various factors, such as the complexity and scope of your project, customizations needed, integration required, and more. Hence, we request that you contact us for an accurate timeline and detailed quotation."
                  }
                }]
              }                                    
            `}
          </script>
        </Helmet>
        <Layout>
          <div className="service-detail-page">
            <Banner content={banner} page={"service"} />
            <CommonServiceComponent data={commonContent6} mode={"grey"} />
            <ServiceTab tabs={tabs} />
            <CommonServiceComponent data={commonContent} mode={"light"} />
            <Cta mode="grey" data={cta} />
            <CommonServiceComponent data={commonContent2} mode={"dark"} />
            <CommonServiceComponent data={commonContent3} mode={"light"} />
            <Cta data={cta2} />
            <Clients title={clientsTitle} />
            <ServiceTab tabs={tabs} />
            <CommonServiceComponent data={commonContent4} mode={"light"} />
            <Cta mode="grey" data={cta3} />
            <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
              <div className="container">
                <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
                    Our Successful Projects by GatsbyJS Development 
                </h2>
              </div>
              {projectData.map((data, index) => (
                <div key={index}>
                  <ProjectSection num={index} lastLength={projectData} data={data} />
                </div>
              ))}
            </section>
            <Testimonials title={"Client’s Take On Us"} />
            <Cta data={cta4} />
            <CommonServiceComponent data={commonContent5} condition mode={"light"} />
            <ServiceTab tabs={tabs} mode={"split"} />
            <Faq Questions={Questions} section des={faqDes} link faqData={faqData} />
            <Cta mode="grey" data={cta5} />
            {/* <KnowMoreSection /> */}
          </div>
        </Layout>
      </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo title="GatsbyJS Development | GatsbyJS Web Development Agency" description="Create an incredibly fast and visually appealing website with exceptional performance using Octet Design Studio’s GatsbyJs Development." />
)